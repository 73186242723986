const Products = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Products.vue");

const productRoutes = [
  {
    path: "products",
    name: "products",
    component: Products,
    meta: { requiresAuth: true },
  },
];

export { productRoutes };
