export const PERMISSIONS = {
  MANAGER: [
    "home",
    "dashboard",
    "profile",
    "managerConfig",
    "stockInventories",
    "stockInventory",
    "createStockInventory",
    "editStockInventory",
    "getBatchs",
    "machines",
    "stock",
    "machine",
    "machineEdit",
    "createMachine",
    "machineReportMoviments",
    "machineInventories",
    "machineInventory",
    "createMachineInventory",
    "editMachineInventory",
    "generalStock",
    "stockReportMoviments",
    "products",
    "product",
    "productEdit",
    "purchases",
    "salesReport",
    "minimumStockReport",
    "batchs",
  ],
  STOCK_MANAGER: [
    "home",
    "managerConfig",
    "stockInventories",
    "stockInventory",
    "createStockInventory",
    "machineInventories",
    "machineInventory",
    "createMachineInventory",
    "editMachineInventory",
    "editStockInventory",
  ],
};

export const USER_TYPES = {
  1: "ADMIN",
  2: "MANAGER",
  3: "STOCK_MANAGER",
};
