const Purchases = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Purchases.vue");

const purchaseRoutes = [
  {
    path: "purchases",
    name: "purchases",
    component: Purchases,
    meta: { requiresAuth: true },
  },
];

export { purchaseRoutes };
