import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import Unauthorized from "@/pages/Unauthorized.vue";
import { machineRoutes } from "./machine";
import { productRoutes } from "./product";
import { purchaseRoutes } from "./purchase";
import { reportRoutes } from "./report";
import { stockRoutes } from "./stock";
import { batchRoutes } from "./batch";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const DefaultPage = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/DefaultPage.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Config = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Config.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Login = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Authentication/Login.vue");
const SignUp = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Authentication/SignUp.vue");
const ConfirmSignUp = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/Authentication/ConfirmSignUp.vue"
  );
const ManagerConfig = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Manager/ManagerConfig.vue");

const Manager = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Manager/Manager.vue");

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/signup/:token",
    name: "signup",
    component: SignUp,
    meta: { guest: true },
  },
  {
    path: "/signup/confirm",
    name: "confirmSignUp",
    component: ConfirmSignUp,
    meta: { guest: true },
  },
  {
    path: "/manager/config",
    name: "managerConfig",
    component: ManagerConfig,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/home",
    meta: { requiresAuth: true },
    children: [
      {
        path: "home",
        name: "home",
        component: DefaultPage,
        meta: { requiresAuth: true },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        path: "maps",
        name: "maps",
        component: Maps,
        meta: { requiresAuth: true },
      },
      {
        path: "config",
        name: "config",
        component: Config,
        meta: { requiresAuth: true },
      },
      {
        path: "manager/:id",
        name: "manager",
        component: Manager,
        props: true,
        meta: { requiresAuth: true },
      },
      ...machineRoutes,
      ...stockRoutes,
      ...productRoutes,
      ...purchaseRoutes,
      ...reportRoutes,
      ...batchRoutes,
    ],
  },
  { path: "*", component: NotFound },
  { path: "/unauthorized", component: Unauthorized, name: "Unauthorized" },
];
/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
