const Batchs = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Batch/Batchs.vue");
const batchRoutes = [
  {
    path: "batchs",
    name: "batchs",
    component: Batchs,
    meta: { requiresAuth: true },
  },
];

export { batchRoutes };
